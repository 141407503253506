import React from 'react'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Typography, TextField, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import Loading from '../../common/Loading'

import { styles } from './styles'

const PatientLookup = (props) => {
    const { patients, loading } = props

    const useStyles = makeStyles(styles)
    const classes = useStyles()

    const [patientLookupForm, setLookupForm] = React.useState({
        dob: null,
        lastName: '',
    })

    const handleDateChange = (e) => {
        setLookupForm({ ...patientLookupForm, dob: e })
    }

    const handleSubmit = () => {
        const form = {
            dob: format(new Date(patientLookupForm.dob.toLocaleDateString()), 'yyyy-MM-dd'),
            lastName: patientLookupForm.lastName.toLowerCase(),
        }

        props.handleSearch(form)
    }
    return (
        <div className={classes.patientSearch} style={{ width: "100%", maxWidth: "600px", margin: "0 auto", textAlign: "center" }}>
            <Typography variant="h5">In-Process New Patient</Typography>
            <div style={{ maxWidth: "250px" }}>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Last Name"
                        fullWidth
                        value={patientLookupForm.lastName}
                        onChange={(e) => setLookupForm({ ...patientLookupForm, ...{ lastName: e.target.value } })}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            fullWidth
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date of Birth"
                            value={patientLookupForm.dob}
                            openTo="year"
                            disableFuture={true}
                            views={['year', 'month', 'day']}
                            autoOk={true}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: "5px" }}>
                    <Button fullWidth variant="contained" onClick={handleSubmit}>
                        Search
                    </Button>
                    <hr/>
                    <span>OR</span>
                    <hr/>
                    <a style={{ display: "block", width: "100%" }} href="/new-patient" target="_blank">
                        <Button fullWidth variant="contained" type="button">
                            Create New Patient
                        </Button>
                    </a>
                </div>
            </div>

            <div className={classes.patientSearch}>
                {patients && patients.length ? (
                    patients.map((pat) => (
                        <div key={pat.patientID} className={classes.patientConfirm}>
                            <Typography variant="h6">{`${pat.firstName} ${pat.lastName}`}</Typography>
                            <Typography variant="h6">{pat.dateOfBirth}</Typography>
                            <Typography variant="h6">{pat.email}</Typography>
                            <Button variant="contained" onClick={() => props.setPatient(pat)}>
                                Confirm
                            </Button>
                        </div>
                    ))
                ) : loading ? (
                    <Loading />
                ) : (
                    <Typography style={{ marginBottom: '10px' }}>No patients found</Typography>
                )}
            </div>
        </div>
    )
}

export default PatientLookup
