import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import { isValidEmail } from '../../../utilities/helpers'

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            marginBottom: 20,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default function ForgotPassword(props) {
    const { onChangeView, onSubmit } = props
    const classes = useStyles()
    const [errors, setErrors] = useState({ email: false })
    const [values, setValues] = useState({ email: '' })

    const handleChangeEmail = (event) => setValues({ ...values, email: event.target.value.toLowerCase() })
    const handleKeyPress = (event) => (event.key === 'Enter' ? validate() : null)
    const validate = () => {
        setErrors({ email: false })
        if (isValidEmail(values.email) !== true) {
            setErrors({ ...errors, email: 'Must be a valid email' })
            return
        }
        onSubmit(values.email)
    }

    return (
        <Card>
            <CardContent className={classes.content}>
                <Typography variant="h5">Reset Password</Typography>
                <TextField
                    name="email"
                    type="text"
                    label="Email"
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={values.email}
                    onChange={handleChangeEmail}
                    onKeyDown={handleKeyPress}
                />
            </CardContent>
            <CardActions className={classes.actions}>
                <Button variant="contained" color="primary" onClick={() => validate()}>
                    Submit
                </Button>
                <Button onClick={() => onChangeView('login')}>Login</Button>
            </CardActions>
        </Card>
    )
}
