import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setMsg } from '../../../redux/modules/application/actions'

import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

import Login from './login'
import NewAcct from './newAcct'
import ForgotPassword from './forgotPassword'
import { reqLogin, reqResetPassword, reqCreateAcct } from '../../../redux/modules/authentication'
import { addNewProvider, findProvider } from '../../../services/firebase'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        'align-items': 'stretch',
        width: '100%',
        'min-height': '100vh',
        'justify-content': 'flex-start',
        'background-image': `url(${process.env.PUBLIC_URL}/portal-background.jpg)`,
        'background-size': 'cover',
    },
    top: {
        [theme.breakpoints.down('sm')]: {
            height: '60px',
        },
        [theme.breakpoints.up('md')]: {
            height: '80px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '100px',
        },
        width: '100%',
        'background-color': 'white',
        display: 'flex',
        'align-content': 'center',
        'justify-content': 'center',
    },
    main: {
        'align-self': 'stretch',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 1rem 0 1rem',
        },
        [theme.breakpoints.up('md')]: {
            padding: '2rem',
        },
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            margin: '1rem auto 3rem auto',
        },
        [theme.breakpoints.up('md')]: {
            margin: '2rem auto 5rem auto',
        },
    },
    login: {
        'max-width': '350px',
        margin: '0 auto',
    },
}))

export default function ProviderLogin() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { Application, Authentication } = useSelector((state) => state)
    const { logoSrc } = Application
    const { authenticated, provider } = Authentication

    const [view, setView] = useState('login')
    const [redirect, setRedirect] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (authenticated) {
            if (provider) {
                setRedirect('/on-site')
            }
        }
    }, [authenticated, provider])

    let loginView
    const handleChangeView = (view) => setView(view)

    const handleLogin = async ({ email, password }) => {
        // Find provider
        setLoading(true)
        const isProvider = await findProvider({ email })
        setLoading(false)

        if (isProvider) {
            dispatch(reqLogin({ email, password }))
            setRedirect('/')
        } else {
            dispatch(setMsg({ text: 'No provider account found', severity: 'error' }))
            setRedirect('/login')
        }
    }

    const handleNewAcct = async (vals) => {
        try {
            const { email, password } = vals
            // Make new provider
            const res = await addNewProvider({ email })

            if (res) {
                dispatch(reqCreateAcct({ email, password }))
                dispatch(setMsg({ text: 'Successfully added provider account', severity: 'info' }))
                setRedirect('/')
            } else {
                dispatch(setMsg({ text: 'Error adding provider account', severity: 'error' }))
            }
        } catch (e) {
            dispatch(setMsg({ text: e.message, severity: 'error' }))
        }
    }

    const handleResetPassword = (email) => {
        dispatch(reqResetPassword(email))
        setView('login')
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    switch (view) {
        case 'login':
            loginView = <Login onChangeView={handleChangeView} onSubmit={handleLogin} loading={loading} />
            break
        case 'newAcct':
            loginView = <NewAcct onChangeView={handleChangeView} onSubmit={handleNewAcct} />
            break
        case 'forgotPassword':
            loginView = <ForgotPassword onChangeView={handleChangeView} onSubmit={handleResetPassword} />
            break
        default:
            loginView = <Login onChangeView={handleChangeView} onSubmit={handleLogin} />
    }

    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <img src={logoSrc} className="main-logo main-logo-top" alt="Nexsun Diagnostic Laboratories" />
            </div>
            <div className={classes.main}>
                <Typography variant="h4" align="center" className={classes.title}>
                    PATIENT PORTAL
                </Typography>

                <div className={classes.login}>{loginView}</div>
            </div>
        </div>
    )
}
