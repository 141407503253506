import React from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, Typography } from '@mui/material'
import { formatAddress } from '../../../utilities/helpers'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
}))

export default function ProviderInfo(props) {
    const { data } = props
    const classes = useStyles()
    const { providerAddress1, providerAddress2, providerCity, providerNPI, providerName, providerPhone, providerState, providerZip } = data

    const address = formatAddress({
        address1: providerAddress1,
        address2: providerAddress2,
        city: providerCity,
        state: providerState,
        zip: providerZip,
    })

    const renderText = (label, value) => (value ? <TextField multiline label={label} defaultValue={value} inputProps={{ readOnly: true }} /> : null)

    return (
        <div style={{ width: "100%", marginTop: "15px" }}>
            <Typography variant="h5" className={classes.heading}>
                Provider
            </Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{providerName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>NPI</TableCell>
                        <TableCell>{providerNPI}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{providerPhone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>{address}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}
