import actions from './actions'

const initialState = {
    msg: {},
    showLoader: true,
    initialLoading: true,
    logoSrc: `${process.env.PUBLIC_URL}/mainlogo.svg`,
    dims: {
        width: 0,
        height: 0,
    },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_MSG:
            return {
                ...state,
                msg: action.severity ? { text: action.text, severity: action.severity } : action.msg,
            }

        case actions.SET_SHOW_LOADER:
            return {
                ...state,
                showLoader: action.showLoader,
            }

        case actions.SET_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: action.initialLoading,
            }

        case actions.SET_VIEW_DIMS:
            return {
                ...state,
                dims: {
                    width: action.width,
                    height: action.height,
                },
            }

        default:
            return state
    }
}
