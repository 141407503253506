import React from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, Typography } from '@mui/material'
import { formatAddress, formatDate } from '../../../utilities/helpers'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
}))

export default function PatientInfo(props) {
    const { data } = props
    const classes = useStyles()
    const {
        patientAddress1,
        patientAddress2,
        patientAddressCity,
        patientAddressState,
        patientAddressZip,
        patientDOB,
        patientEmail,
        patientEthnicity,
        patientFirstName,
        patientGender,
        patientLastName,
        patientPhone,
        patientRace,
    } = data

    const address = formatAddress({
        address1: patientAddress1,
        address2: patientAddress2,
        city: patientAddressCity,
        state: patientAddressState,
        zip: patientAddressZip,
    })

    const renderText = (label, value) => (value ? <TextField multiline label={label} defaultValue={value} inputProps={{ readOnly: true }} /> : null)

    return (
        <div style={{width: "100%"}}>
            <Typography variant="h5" className={classes.heading}>
                Patient Info
            </Typography>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{patientFirstName} {patientLastName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{patientEmail}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>{address}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{patientPhone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>DOB</TableCell>
                        <TableCell>{formatDate(patientDOB)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{patientPhone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Gender</TableCell>
                        <TableCell>{patientGender}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ethnicity</TableCell>
                        <TableCell>{patientEthnicity}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Race</TableCell>
                        <TableCell>{patientRace}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}
