import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Typography, Card } from '@mui/material'
import { Redirect } from 'react-router-dom'
import Login from './login'
import NewAcct from './newAcct'
import ForgotPassword from './forgotPassword'
import { reqLogin, reqResetPassword, reqValidateAcct, reqValidateAcctWithPatientId, reqCreateAcct } from '../../../redux/modules/authentication'
import { checkDobAndEmail, addNewProvider, findProvider, createEmailAccount } from '../../../services/firebase'
import { setMsg } from '../../../redux/modules/application/actions'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        'align-items': 'stretch',
        width: '100%',
        'min-height': '100vh',
        'justify-content': 'center',
        'background-image': `url(${process.env.PUBLIC_URL}/portal-background.jpg)`,
        'background-size': 'cover',
    },
    top: {
        [theme.breakpoints.down('sm')]: {
            height: '60px',
        },
        [theme.breakpoints.up('md')]: {
            height: '80px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '100px',
        },
        width: '100%',
        'background-color': 'white',
        display: 'flex',
        'align-content': 'center',
        'justify-content': 'center',
    },
    main: {
        'align-self': 'stretch',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0px',
        },
    },
    subtitle: {
        [theme.breakpoints.down('sm')]: {
            margin: '0.5rem ',
        },
        [theme.breakpoints.up('md')]: {
            margin: '2rem auto 4rem auto',
        },
    },
    login: {
        'max-width': '350px',
        margin: '0 auto',
    },
}))

export default function LoginPage() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { Application, Authentication,  } = useSelector((state) => state)
    const { initialLoading } = useSelector((state) => state.Application)

    const { authenticated } = Authentication
    const [isLoading, setLoading] = useState(false)

    const [redirect, setRedirect] = useState(null)
    const [view, setView] = useState('login')

    let loginView

    const handleChangeView = (view) => setView(view)

    const handleNewAcct = async (vals) => {
        const { email, password, dob, id, accountType } = vals
        if( accountType === "patient" ) {
            if (dob) {
                setLoading(true)
                const res = await checkDobAndEmail(email,dob.toLocaleDateString())
                setLoading(false)
                if (res.result) {
                    dispatch(reqCreateAcct({ email, password }))
                    dispatch(setMsg({ text: 'Successfully registered. Welcome!', severity: 'success' }))
                    setRedirect('/')
                } else {
                    dispatch(setMsg({ text: 'Could not find a test result associated to this email or dob', severity: 'error' }))
                }
            } else {
                dispatch(reqValidateAcctWithPatientId({ email, password, id }))
            }
        } else if ( accountType === "provider" ) { //Create a Provider
            try {
                // Make new provider
                setLoading(true)
                const res = await addNewProvider({ email })
                setLoading(false)

                if ( res?.id ?? false ) {
                    dispatch(reqCreateAcct({ email, password }))
                    dispatch(setMsg({ text: 'Successfully registered. Welcome!', severity: 'success' }))
                    setRedirect('/') 
                } else {
                    dispatch(setMsg({ text: 'This email is NOT authenticated to register as a provider', severity: 'error' }))
                }
            } catch (e) {
                dispatch(setMsg({ text: e.message, severity: 'error' }))
            }
        }
    }

    const handleLogin = async ({ email, password, accountType }) => {
        if( accountType === "provider" ) {
            // Find provider
            // setLoading(true)
            const isProvider = await findProvider({ email })
            // setLoading(false)
            if (isProvider) {
                dispatch(reqLogin({ email, password }))
                setRedirect('/')
            } else {
                dispatch(setMsg({ text: 'Invalid Email or Password.', severity: 'warning' }))
            }
        } else if( accountType === "patient" ) { //Login as a Patient
            dispatch(reqLogin({ email, password }))
        }
    }

    const handleResetPassword = (email) => {
        dispatch(reqResetPassword(email))
        setView('login')
    }
    
    useEffect(() => {
        if (authenticated) {
            setRedirect('/')
        }
    }, [authenticated])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    switch (view) {
        case 'login':
            loginView = <Login onChangeView={handleChangeView} onSubmit={handleLogin} isLoading={isLoading} />
            break
        case 'newAcct':
            loginView = <NewAcct onChangeView={handleChangeView} onSubmit={handleNewAcct} isLoading={isLoading} />
            break
        case 'forgotPassword':
            loginView = <ForgotPassword onChangeView={handleChangeView} onSubmit={handleResetPassword} />
            break
        default:
            loginView = <Login onChangeView={handleChangeView} onSubmit={handleLogin} />
    }

    return (
        <div className={classes.root}>
            {/* <div className={classes.top}>
                <img src={logoSrc} className="main-logo main-logo-top" alt="Nexsun Diagnostic Laboratories" />
            </div> */}
            {!initialLoading &&
            <div className={classes.main}>
                <div className={classes.login}>{loginView}</div>
            </div>
            }
        </div>
    )
}
