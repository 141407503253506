import React from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, Typography } from '@mui/material'
import { formatDate } from '../../../utilities/helpers'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    result: ({ color }) => ({
        color,
    }),
    textField: {
        marginTop: 5,
    },
    approval: {
        display: 'flex',
        flexDirection: 'column',
        margin: '30px 0px',
        '& img': {
            margin: '10px 0px',
            maxWidth: 300,
            width: '100%',
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
}))

export default function Result(props) {
    const { data, signature } = props
    const { resultDefinition, resultText, approvingPerson, approvingPersonTitle, patientAccessionID, testName, testResultDate, testSpecimenType, sampleReceivedDate } = data
    let color

    switch (resultText) {
        case 'Detected':
            color = 'red'
            break
        case 'Not Detected':
            color = 'green'
            break
        case 'Inconclusive':
            color = 'black'
            break
        default:
            color = 'black'
            break
    }

    const classes = useStyles({ color })

    const renderText = (label, value) => (value ? <TextField multiline className={classes.textField} label={label} defaultValue={value} inputProps={{ readOnly: true }} /> : null)

    return (
        <div className={classes.root}>

            <div style={{ borderLeft: `2px solid ${color}`, padding: "15px", margin: "15px", color: color }}>
                {resultDefinition}
            </div>

            <Typography variant="h5" className={classes.heading}>
                Test Information
            </Typography>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Result</TableCell>
                        <TableCell><strong>{resultText ?? 'N/A'}</strong></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Approved By</TableCell>
                        <TableCell>{approvingPerson} ({approvingPersonTitle})
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sample Date</TableCell>
                        <TableCell>{formatDate(sampleReceivedDate)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Result Date</TableCell>
                        <TableCell>{formatDate(testResultDate)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Accession #</TableCell>
                        <TableCell>{patientAccessionID}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Test Name</TableCell>
                        <TableCell>{testName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Specimen Type</TableCell>
                        <TableCell>{testSpecimenType}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}
