import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker';
import { isValidEmail } from '../../../utilities/helpers'
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import DoneAll from '@mui/icons-material/DoneAll';
import IconButton from '@mui/material/IconButton';

import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';

import { Collapse } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            marginBottom: 20,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    patientIdField: {
        '& label': {
            color: 'blue',
        },
    },
}))

export default function NewAcct(props) {
    const { onChangeView, onSubmit, isLoading } = props
    const classes = useStyles()
    const [errors, setErrors] = useState({ email: false, password: false, password2: false, date: false })
    const [values, setValues] = useState({ email: '', password: '', password2: '', dob: null, id: '', extra: '', accountType: '' })
    const [shouldShowPatientId, setPatientIdVis] = useState(false)

    const { msg = {} } = useSelector((state) => state.Application)

    const { Application, Authentication } = useSelector((state) => state)
    const { logoSrc } = Application

    // Only show patient ID option if inital attempt with birthdate was unsuccessful
    React.useEffect(() => {
        if (msg?.text) {
            if (msg.text.includes('Birthdate') && msg.text.includes('does not match')) {
                setPatientIdVis(false)
                setValues({ ...values, dob: null })
            }
        }
    }, [msg])

    const handleDateChange = (dob) => setValues({ ...values, dob })

    const handleChange = (event) => setValues({ ...values, [event.target.name]: event.target.value })
    const handleChangeEmail = (event) => setValues({ ...values, email: event.target.value.toLowerCase().trim() })
    const handleKeyPress = (event) => (event.key === 'Enter' ? validate() : null)

    const validate = () => {
        setErrors({ email: false, password: false, password2: false })

        if (isValidEmail(values.email) !== true) {
            setErrors({ ...errors, email: 'Must be a valid email' })
            return
        }

        if (values.password !== values.password2) {
            setErrors({ ...errors, password2: 'Passwords do not match' })
            return
        }

        if (values.password.length < 6 || values.password2.length < 6) {
            setErrors({ ...errors, password: 'Password must be at least 6 characters' })
            return
        }

        if ( values.accountType == "" ) {
            alert("Please choose an account type to register for")
            return
        }

        if (values.id) {
            let parsedId = values.id.split('')
            let count = 0

            while (parsedId[0] === '0' && count < 25) {
                parsedId.shift()
                count++
            }

            values.id = parsedId.join('')
        }

        onSubmit(values)
    }

    const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    let accountTypeStyle = { margin: "5px", opacity: "0.4", cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", border: "1px solid #aaa", borderRadius: "15px" };
    let selectedAccount = { ...accountTypeStyle, opacity: "0.8" }

    return (
        <div>
            <Card style={{ textAlign: "center", padding: "25px" }}>
                <img src={logoSrc} style={{ width: "75%", margin: "0 auto" }} className="main-logo main-logo-top" alt="Nexsun Diagnostic Laboratories" />
                <CardContent className={classes.content} sx={{ padding: "0px", paddingTop: "25px" }}>
                    <span>Register an Account Type</span>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", margin: "10px 0", width: "100%" }}>
                        <div 
                            style={ values.accountType === "provider" ? selectedAccount : accountTypeStyle }
                            onClick={ () => setValues({...values, accountType: "provider"}) }
                        >
                            <BadgeIcon style={{ fontSize: "5em" }}/>
                            <span>Provider</span>
                        </div>
                        <div 
                            style={ values.accountType === "patient" ? selectedAccount : accountTypeStyle } 
                            onClick={ () => setValues({...values, accountType: "patient"}) }
                        >
                            <PersonIcon style={{ fontSize: "5em" }}/>
                            <span>Patient</span>
                        </div>
                    </div>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                        <div style={{textAlign: 'left', marginBlock: "-12px", width: "100%", paddingTop: "20px", display: "flex", alignItems: "center"}}>
                            <EmailIcon style={{ fontSize: "1em" }} />
                            <span style={{paddingLeft: "5px"}}>Email</span>
                        </div>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="email"
                            type="text"
                            helperText={
                                errors.email 
                                ? errors.email 
                                : null
                            }
                            error={errors.email ? true : false}
                            value={values.email}
                            onChange={handleChangeEmail}
                            onKeyDown={handleKeyPress}
                        />
                        <Collapse in={ values.accountType === 'patient' }><div style={{ textAlign: "left", fontSize: "0.9em", opacity: "0.8", marginBottom: "15px", marginTop: "-5px" }}>Email must match the email address of a submitted test</div></Collapse>
                    </FormControl>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                        <div style={{textAlign: 'left', marginBlock: "-12px", width: "100%", paddingTop: "20px", display: "flex", alignItems: "center"}}>
                            <LockIcon style={{ fontSize: "1em" }} />
                            <span style={{paddingLeft: "5px"}}>Password</span>
                        </div>
                        <TextField
                            fullWidth
                            id="verifypassword"
                            type={values.showPassword ? "text" : "password"}
                            name="password"
                            margin="normal"
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={values.password}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </FormControl>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                        <div style={{textAlign: 'left', marginBlock: "-12px", width: "100%", paddingTop: "20px", display: "flex", alignItems: "center"}}>
                            <DoneAll style={{ fontSize: "1em" }} />
                            <span style={{paddingLeft: "5px"}}>Verify Password</span>
                        </div>
                        <TextField
                            fullWidth
                            name="password2"
                            type={values.showPassword ? "text" : "password"}
                            margin="normal"
                            helperText={errors.password2}
                            error={errors.password2 ? true : false}
                            value={values.password2}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </FormControl>
                    <div className="datePicker">
                    <Collapse in={values.accountType === "patient"}>
                        <div style={{textAlign: 'left', marginBlock: "-12px", paddingTop: "20px", display: "flex", alignItems: "center", marginLeft: "-7px", marginBottom: "5px"}}>
                            <DoneAll style={{ fontSize: "1em" }} />
                            <span style={{paddingLeft: "5px"}}>Verify Date of Birth</span>
                        </div>
                        <DatePicker
                            style={{ width: "100%", marginTop: "15px"}}
                            fullWidth
                            disableToolbar
                            format="MM/dd/yyyy"
                            margin="normal"
                            openTo="year"
                            disableFuture={true}
                            views={['year', 'month', 'day']}
                            renderInput={(params) => <TextField {...params} />}
                            autoOk={true}
                            value={values.dob}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Collapse>
                    </div>
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button fullWidth variant="contained" color="secondary" onClick={() => validate()}>
                        { isLoading ? <CircularProgress size="20px" style={{ color: "#fff", padding: "2.5px" }} /> : <span>Register</span> }
                    </Button>
                </CardActions>
            </Card>
            <Collapse in={!isLoading}><Button style={{ color: "#fff", marginTop: "15px" }} onClick={() => onChangeView('login')}>← Go back to Login</Button></Collapse>
        </div>
    )
}
