import React from 'react'
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatAddressInline, formatDate } from '../../../utilities/helpers'
import PdfLogo from '../../common/PdfLogo'

const styles = StyleSheet.create({
    firstPage: {
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '1in',
        paddingBottom: '0.75in',
        paddingHorizontal: '0.75in',
    },
    page: {
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0.75in',
    },
    h1: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        marginTop: 12,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 10,
        width: 500,
    },
    headerText: { fontSize: 11, marginHorizontal: 5 },
    p: {
        fontSize: 10,
        marginTop: 10,
        textAlign: 'justify',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        width: 250,
    },
    rowBetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'left',
    },
    rowCenter: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
    },
    textField: {
        borderBottomColor: '#666',
        borderBottomWidth: 1,
        borderBottom: 'solid',
        marginTop: 3,
    },
    'col-1': { width: '8.333333333333334%' },
    'col-2': { width: '16.666666666666664%' },
    'col-3': { width: '25%' },
    'col-4': { width: '33.33333333333333%' },
    'col-5': { width: '41.66666666666667%' },
    'col-6': { width: '50%' },
    'col-7': { width: '58.333333333333336%' },
    'col-8': { width: '66.66666666666666%' },
    'col-9': { width: '75%' },
    'col-10': { width: '83.33333333333334%' },
    'col-11': { width: '91.66666666666666%' },
    'col-12': { width: '100%' },
})

export default function PDFView(props) {
    const { data, signature } = props
    const {
        labAddress,
        labPhone,
        labCLIA,
        approvingPerson,
        approvingPersonTitle,
        patientAccessionID,
        patientAddress1,
        patientAddress2,
        patientAddressCity,
        patientAddressState,
        patientAddressZip,
        patientDOB,
        patientEmail,
        patientEthnicity,
        patientFirstName,
        patientGender,
        patientLastName,
        patientPhone,
        patientRace,
        providerAddress1,
        providerAddress2,
        providerCity,
        providerNPI,
        providerName,
        providerPhone,
        providerState,
        providerZip,
        resultDefinition,
        testName,
        testResultDate,
        resultText,
        testSpecimenType,
        sampleReceivedDate,
        labSpecificVerbiage1,
        labSpecificVerbiage2,
        labSpecificVerbiage3,
    } = data
    let color

    switch (resultText) {
        case 'Detected':
            color = 'red'
            break
        case 'Not Detected':
            color = 'green'
            break
        case 'Inconclusive':
            color = 'black'
            break
        default:
            color = 'black'
            break
    }

    const patientAddress = formatAddressInline({
        address1: patientAddress1,
        address2: patientAddress2,
        city: patientAddressCity,
        state: patientAddressState,
        zip: patientAddressZip,
    })

    const providerAddress = formatAddressInline({
        address1: providerAddress1,
        address2: providerAddress2,
        city: providerCity,
        state: providerState,
        zip: providerZip,
    })

    const renderText = (label, value, colWidth) => {
        var colWidthStyle = colWidth ? styles[colWidth] : styles['col-2']
        var textFiledStyle = Object.assign({}, styles.textField, colWidthStyle)
        return value ? (
            <View style={textFiledStyle}>
                <Text style={{ color: '#666', fontSize: 10, width: '100%' }}>{label}</Text>
                <Text style={{ fontSize: 12, width: '100%' }}>{value}</Text>
            </View>
        ) : null
    }

    return (
        <Document>
            <Page wrap size="A4" style={styles.page}>
                <View style={styles.header}>
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <PdfLogo></PdfLogo>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        <Text style={styles.headerText}>{labAddress}</Text>
                        <View style={styles.rowCenter}>
                            <Text style={styles.headerText}>{labCLIA}</Text>
                            <Text style={styles.headerText}>{labPhone}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ ...styles.row }}>
                    <View
                        style={{
                            ...styles.col,
                            justifyContent: 'flex-start',
                            width: '100%',
                        }}
                    >
                        <Text style={styles.h1}>Result</Text>
                        <Text style={{ fontSize: 20, fontWeight: 'bold', color }}>{resultText}</Text>
                        <Text style={{ fontSize: 12, color }}>{resultDefinition}</Text>
                        <View style={{ marginTop: 10 }}>
                            <Text style={{ fontSize: 10 }}>Approved By:</Text>
                            {signature ? <Image style={{ width: 200 }} src={signature} /> : null}
                            <Text style={{ fontSize: 12 }}>
                                {approvingPerson} ( {approvingPersonTitle} )
                            </Text>
                            <View style={styles.row}>
                                {renderText('Sample Date', formatDate(sampleReceivedDate))}
                                {renderText('Result Date', formatDate(testResultDate))}
                                {renderText('Accession #', patientAccessionID)}
                                {renderText('Test Name', testName, 'col-6')}
                            </View>
                            <View style={styles.row}>{renderText('Specimen Type', testSpecimenType, 'col-12')}</View>
                        </View>
                    </View>
                </View>

                <Text style={styles.h1}>Patient Info</Text>
                <View style={styles.row}>
                    {renderText('Name', `${patientFirstName} ${patientLastName}`, 'col-4')}
                    {renderText('Email', patientEmail, 'col-4')}
                    {renderText('Phone', patientPhone, 'col-2')}
                    {renderText('DOB', formatDate(patientDOB), 'col-2')}
                </View>

                <View style={styles.row}>
                    {renderText('Gender/Ethnicity/Race', `${patientGender}/${patientEthnicity}/${patientRace}`, 'col-4')}
                    {renderText('Address', patientAddress, 'col-8')}
                </View>

                <Text style={styles.h1}>Provider</Text>
                <View style={styles.row}>
                    {renderText('Name', providerName, 'col-4')}
                    {renderText('NPI', providerNPI, 'col-4')}
                    {renderText('Phone', providerPhone, 'col-4')}
                </View>
                <View style={styles.row}>{renderText('Address', providerAddress, 'col-12')}</View>

                <Text style={styles.p}>{labSpecificVerbiage1}</Text>
                <Text style={styles.p}>{labSpecificVerbiage2}</Text>
                <Text style={styles.p}>{labSpecificVerbiage3}</Text>
            </Page>
        </Document>
    )
}
