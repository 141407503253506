import React from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { reqLogout } from '../../redux/modules/authentication'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    appBar: {
        justifyContent: 'flex-start',
    },
    spacer: {
        flexGrow: 1,
    },
    img: {
        height: 30,
        marginRight: "15px",
    },
}))

export default function MyAppBar(props) {
    const { onMenuClick } = props
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { Application: { logoSrc } } = useSelector((state) => state)
    const imgSrc = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com/o/static%2Fstatic_HIPPA.png?alt=media&token=85a46490-e4a8-42b1-a64e-e441981454f6`

    const {
        Authentication: { provider },
    } = useSelector((state) => state)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleLogout = () => {
        dispatch(reqLogout())
    }
    const handleNavigate = (path) => {
        if( path === "/on-site" ) {
            window.location.reload(); //Reloads the page in the case when viewing a patient
        } else {
            history.push(path)
        }
    }
    return (
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar>
                <img src={logoSrc} className="main-logo" alt="Nexsun Diagnostic Laboratories" style={{ height: "25px" }} />
                <div className={classes.spacer}>{` `}</div>
                <img className={classes.img} src={imgSrc} alt="HIPAA" />
                <IconButton 
                    edge="start" 
                    className={classes.menuButton} color="inherit"
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    { provider && 
                    <MenuItem onClick={() => handleNavigate("/on-site")}>
                        Patient On-Site
                    </MenuItem>
                    }
                    { provider && 
                    <MenuItem onClick={() => handleNavigate("/manifest")}>
                        Patient Manifest
                    </MenuItem>
                    }
                    <MenuItem onClick={handleLogout}>
                        Logout
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}

{/* <MenuDrawer open={showMenu} onClose={() => setShowMenu(false)}>
                {provider ? (
                    <List className={classes.drawerContent}>
                        <Link to="/on-site" className={classes.link}>
                            Process Patients
                        </Link>
                        <Link to="/manifest" className={classes.link}>
                            Patient Manifest
                        </Link>
                        <Button onClick={handleLogout}>Logout</Button>
                    </List>
                ) : (
                    <List className={classes.drawerContent}>
                        <Button onClick={handleLogout}>Logout</Button>
                    </List>
                )}
            </MenuDrawer>  */}