import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TestsGrid from '../TestsGrid'
import { reqLogout } from '../../../redux/modules/authentication'

const useStyles = makeStyles((theme) => ({
    home: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        height: '100%',
        width: '100%',
        '& img': {
            width: '100%',
            marginBottom: 20,
            maxWidth: 300,
        },
        '& > div': {
            width: '100%',
        },
    },
}))

export default function Home() {
    const classes = useStyles()
    const { Application, Tests } = useSelector((state) => state)
    const { tests = [] } = Tests
    const { logoSrc } = Application
    const {
        Authentication: { provider },
    } = useSelector((state) => state)
    const dispatch = useDispatch()
    const history = useHistory()

    const handleLogout = () => {
        dispatch(reqLogout())
    }

    const handleNavigate = (path) => {
        history.push(path)
    }

    return (
        <div className={classes.home}>
            {/* <img src={logoSrc} className="main-logo" alt="Nexsun" /> */}
            {provider ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: "300px" }}>
                    <div style={{ width: "100%", marginBottom: "15px" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                handleNavigate('/on-site')
                            }}
                        >
                            Process Patients
                        </Button>
                    </div>
                    <div style={{ width: "100%" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                handleNavigate('/manifest')
                            }}
                        >
                            Patient Manifest
                        </Button>
                    </div>
                </div>
            ) : null}
            <TestsGrid tests={tests} />
        </div>
    )
}
