import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles'
import { setShowLoader } from '../../redux/modules/application'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

export default function Loader() {
    const { showLoader } = useSelector((state) => state.Application)
    const dispatch = useDispatch()
    const classes = useStyles()
    const handleClose = () => dispatch(setShowLoader(false))

    return showLoader ? (
        <Backdrop className={classes.backdrop} open={showLoader} onClick={handleClose}>
            <CircularProgress color="primary" />
        </Backdrop>
    ) : null
}
