import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import { isValidEmail } from '../../../utilities/helpers'
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            marginBottom: 20,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: "25px"
    },
}))

export default function ForgotPassword(props) {
    const { onChangeView, onSubmit } = props
    const classes = useStyles()
    const [errors, setErrors] = useState({ email: false })
    const [values, setValues] = useState({ email: '' })

    const { Application, Authentication } = useSelector((state) => state)
    const { logoSrc } = Application

    const handleChangeEmail = (event) => setValues({ ...values, email: event.target.value.toLowerCase() })
    const handleKeyPress = (event) => (event.key === 'Enter' ? validate() : null)
    const validate = () => {
        setErrors({ email: false })
        if (isValidEmail(values.email) !== true) {
            setErrors({ ...errors, email: 'Must be a valid email' })
            return
        }
        onSubmit(values.email)
    }

    return (
        <div>
            <Card>
                <CardContent className={classes.content} style={{ padding: "25px" }}>
                    <img src={logoSrc} style={{ width: "75%", margin: "0 auto" }} className="main-logo main-logo-top" alt="Nexsun Diagnostic Laboratories" />
                
                    <div style={{ borderLeft: "2px solid grey", width: "90%", padding: "10px", margin: "15px" }}>
                        Please enter your email address to reset your password.
                    </div>
                    <div style={{textAlign: 'left', marginBlock: "-12px", width: "100%", paddingTop: "20px", display: "flex", alignItems: "center" }}>
                        <EmailIcon style={{ fontSize: "1em" }} />
                        <span style={{paddingLeft: "5px"}}>Email</span>
                    </div>
                    <TextField
                        fullWidth
                        name="email"
                        type="text"
                        margin="normal"
                        helperText={errors.email}
                        error={errors.email ? true : false}
                        value={values.email}
                        onChange={handleChangeEmail}
                        onKeyDown={handleKeyPress}
                    />
                    <Button style={{ marginTop: "15px" }} margin="normal" fullWidth variant="contained" color="primary" onClick={() => validate()}>
                        Reset My Password
                    </Button>
                </CardContent>
            </Card>
            <Button style={{ color: "#fff", marginTop: "15px" }} onClick={() => onChangeView('login')}>← Go back to Login</Button>
        </div>
    )
}
