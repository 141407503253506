import React from 'react'
import { Svg, G, Path } from '@react-pdf/renderer'

export default function PdfLogo() {
    return (
        <Svg style={{ height: 53, width: 292 }}>
            <G transform="scale(0.73) translate(0, 7)">
                <G>
                    <Path
                        fill="#1E1B55"
                        d="M38.7,54.4c2.5,2.5,6.6,2.5,9.1,0c2.5-2.5,2.5-6.6,0-9.1c-2.5-2.5-6.6-2.5-9.1,0c-2.1,2.1-5.6,2.1-7.7,0c-2.5-2.5-6.6-2.5-9.1,0c-2.5,2.5-2.5,6.6,0,9.1c2.5,2.5,6.6,2.5,9.1,0C33.1,52.3,36.5,52.3,38.7,54.4z"
                    ></Path>
                    <Path fill="#049BD8" d="M14.1,54.4c2.5-2.5,2.5-6.6,0-9.1c-2.5-2.5-6.6-2.5-9.1,0c-2.5,2.5-2.5,6.6,0,9.1C7.5,56.9,11.6,56.9,14.1,54.4z"></Path>
                    <Path
                        fill="#A51F62"
                        d="M5,37.6c2.5,2.5,6.6,2.5,9.1,0c2.1-2.1,5.6-2.1,7.7,0c2.5,2.5,6.6,2.5,9.1,0c2.5-2.5,2.5-6.6,0-9.1c-2.5-2.5-6.6-2.5-9.1,0c-2.1,2.1-5.6,2.1-7.7,0c-2.5-2.5-6.6-2.5-9.1,0C2.5,31,2.5,35.1,5,37.6z"
                    ></Path>
                    <Path fill="#049BD8" d="M38.7,28.5c-2.5,2.5-2.5,6.6,0,9.1c2.5,2.5,6.6,2.5,9.1,0c2.5-2.5,2.5-6.6,0-9.1c0,0,0,0,0,0C45.3,25.9,41.2,25.9,38.7,28.5z"></Path>
                    <Path
                        fill="#1E1B55"
                        d="M38.7,20.7c2.5,2.5,6.6,2.5,9.1,0c0,0,0,0,0,0c2.5-2.5,2.5-6.6,0-9.1c-2.5-2.5-6.6-2.5-9.1,0c-2.1,2.1-5.6,2.1-7.7,0c-2.5-2.5-6.6-2.5-9.1,0c-2.5,2.5-2.5,6.6,0,9.1c2.5,2.5,6.6,2.5,9.1,0C33.1,18.6,36.5,18.6,38.7,20.7z"
                    ></Path>
                    <Path fill="#049BD8" d="M14.1,20.7c2.5-2.5,2.5-6.6,0-9.1c-2.5-2.5-6.6-2.5-9.1,0c0,0,0,0,0,0c-2.5,2.5-2.5,6.6,0,9.1C7.5,23.3,11.6,23.3,14.1,20.7z"></Path>
                </G>
                <G>
                    <G>
                        <Path
                            fill="#1E1B55"
                            d="M111.8,17.6c-10.5,0-18.1,8.2-18.1,19.5c0,11.3,8.2,19.5,19.5,19.5c5.4,0,10.2-2,13.6-5.5c0.5-0.5,0.5-1.3,0-1.8c-0.2-0.3-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.2-0.9,0.4c-2.8,3-6.9,4.7-11.7,4.7c-9.2,0-16-6.3-16.7-15.3c0-0.2,0.1-0.5,0.2-0.7c0.2-0.2,0.4-0.3,0.6-0.3h32.4v-0.9C129.8,25.6,122.2,17.6,111.8,17.6z M111.8,20.2c7.9,0,14.1,5.8,15.2,14c0,0.3,0,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3H97.5c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.2-0.7C97.6,25.8,103.7,20.2,111.8,20.2z"
                        ></Path>
                        <Path
                            fill="#1E1B55"
                            d="M150.9,36.5L163.7,20c0.3-0.4,0.4-0.9,0.1-1.4c-0.2-0.5-0.7-0.7-1.2-0.7c-0.4,0-0.8,0.2-1,0.5l-12.4,16l-12.5-16c-0.3-0.3-0.6-0.5-1-0.5c-0.5,0-1,0.3-1.2,0.7c-0.2,0.5-0.2,1,0.1,1.4l12.9,16.4l-13.7,17.7c-0.3,0.4-0.4,0.9-0.1,1.4c0.2,0.5,0.7,0.7,1.2,0.7c0.4,0,0.8-0.2,1.1-0.5l13.3-17.2l13.3,17.2c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.3,1.2-0.8c0.2-0.5,0.2-1-0.2-1.5L150.9,36.5z"
                        ></Path>
                        <Path
                            fill="#1E1B55"
                            d="M184.6,35.9c-6.1-1.2-11.9-2.3-11.9-8.1c0-4.9,4.1-7.6,11.1-7.6c4,0,7.7,1,10.5,2.9c0.3,0.2,0.7,0.3,1,0.2c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.2-1c-0.1-0.4-0.3-0.7-0.6-0.9c-3.1-2-7.6-3.3-11.9-3.3c-10.4,0-14.1,5.6-14.1,10.3c0,8,7.1,9.4,14.1,10.7c6,1.1,11.7,2.2,11.7,7.7c0,5-4.1,7.7-11.8,7.7c-4.7,0-9.5-1.5-12.8-4c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0.1-0.7,0.3-0.9,0.6c-0.3,0.6-0.2,1.3,0.3,1.7c3.3,2.7,8.8,4.4,14.4,4.4c9.2,0,14.7-3.8,14.7-10.2C198.4,38.6,191.4,37.3,184.6,35.9z"
                        ></Path>
                        <Path
                            fill="#1E1B55"
                            d="M73.4,17.6c0,0-0.1,0-0.1,0l-0.1,0l-0.1,0c0,0-0.1,0-0.1,0c-9.9,0-15.5,5.9-15.5,16.2v21.1c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4v-21c0-8.8,4.6-13.6,12.9-13.6s12.9,4.8,12.9,13.6v21c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4V33.8C88.9,23.5,83.2,17.6,73.4,17.6z"
                        ></Path>
                        <Path
                            fill="#1E1B55"
                            d="M257.6,17.6c0,0-0.1,0-0.1,0l-0.1,0l-0.1,0c0,0-0.1,0-0.1,0c-9.9,0-15.5,5.9-15.5,16.2v21.1c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-21c0-8.8,4.6-13.6,12.9-13.6c8.3,0,12.9,4.8,12.9,13.6v21c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4V33.8C273.1,23.5,267.5,17.6,257.6,17.6z"
                        ></Path>
                        <Path
                            fill="#1E1B55"
                            d="M233.2,17.6c-0.8,0-1.4,0.6-1.4,1.4v21c0,8.8-4.6,13.6-12.9,13.6c-8.3,0-12.9-4.8-12.9-13.6V19c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4v21.1c0,10.3,5.7,16.2,15.5,16.2c0,0,0.1,0,0.1,0l0.1,0l0.1,0c0,0,0.1,0,0.1,0c9.9,0,15.5-5.9,15.5-16.2V19C234.6,18.2,234,17.6,233.2,17.6z"
                        ></Path>
                    </G>
                    <G>
                        <Path
                            fill="#AEAEAF"
                            d="M383.2,35.9c-6.1-1.2-11.9-2.3-11.9-8.1c0-4.9,4.1-7.6,11.1-7.6c4,0,7.7,1,10.5,2.9c0.3,0.2,0.7,0.3,1,0.2c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.2-1c-0.1-0.4-0.3-0.7-0.6-0.9c-3.1-2-7.6-3.3-11.9-3.3c-10.4,0-14.1,5.6-14.1,10.3c0,8,7.1,9.4,14.1,10.7c6,1.1,11.7,2.2,11.7,7.7c0,5-4.1,7.7-11.8,7.7c-4.7,0-9.5-1.5-12.8-4c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0.1-0.7,0.3-0.9,0.6c-0.3,0.6-0.2,1.3,0.3,1.7c3.3,2.7,8.8,4.4,14.4,4.4c9.2,0,14.7-3.8,14.7-10.2C396.9,38.6,389.9,37.3,383.2,35.9z"
                        ></Path>
                        <Path fill="#AEAEAF" d="M282.3,1.8c-0.8,0-1.4,0.6-1.4,1.4v51.9c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4V3.2C283.7,2.4,283,1.8,282.3,1.8z"></Path>
                        <Path
                            fill="#AEAEAF"
                            d="M345.7,17.8c-7.1,0-13.1,3.6-16.1,9.8V3.2c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4V37l0,0.1c0,0,0,0.1,0,0.1c0,10.8,7.5,19,17.8,19.5c0,0,0.5,0,1.1,0c0.3,0,0.7,0,1,0c10.3-0.5,17.8-8.7,17.8-19.5C364.5,26,356.6,17.8,345.7,17.8z M361.7,37.3c0,9.4-6.2,16.3-15,16.8l0,0c0,0-1,0.1-2.1,0c-8.8-0.5-15-7.4-15-16.8c0,0,0,0,0,0l0-0.2c0.1-9.7,6.7-16.5,16-16.5C354.9,20.5,361.7,27.5,361.7,37.3z"
                        ></Path>
                        <Path
                            fill="#AEAEAF"
                            d="M306.2,17.8c-5.3,0-10.5,1.8-14.3,4.9c-0.5,0.5-0.6,1.2-0.2,1.8c0.2,0.3,0.5,0.5,0.9,0.5c0.4,0,0.7-0.1,1-0.3c3.4-2.8,7.8-4.3,12.6-4.3c7.5,0,11.5,3.9,11.5,11.3v3.5H304c-12.2,0-14.1,6.6-14.1,10.6c0,6.6,5.4,10.9,13.8,10.9l16.7-0.2V31.7C320.5,22.7,315.4,17.8,306.2,17.8z M304,54.3l0,0.2L304,54.3c-7,0-11.2-3.2-11.2-8.6c0-5.3,3.9-8.1,11.2-8.1h13.6v16.6c-3.1,0-11,0-13.1,0v0L304,54.3z"
                        ></Path>
                    </G>
                </G>
            </G>
        </Svg>
    )
}
