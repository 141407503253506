export const isValidEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const formatAddress = ({ address1 = '', address2 = '', city = '', state = '', zip = '' }) => {
    return `${address1}${address2 && address2.length ? ` ${address2}` : ''}
  ${city}, ${state} ${zip}`
}

export const formatAddressInline = ({ address1 = '', address2 = '', city = '', state = '', zip = '' }) => {
    return `${address1}${address2 && address2.length ? ` ${address2}` : ''} ${city}, ${state} ${zip}`
}

export const formatDate = (rawDate) => {
    if (!rawDate || typeof rawDate !== 'string') return ''
    const year = rawDate.substring(2, 4)
    const month = rawDate.substring(4, 6)
    const day = rawDate.substring(6, 8)
    return `${month}/${day}/${year}`
}

export const scrollToFieldByNameOrId = (field) => {
    let el = document.querySelector(`[name='${field}']`) || document.getElementById(field)
    if (el) {
        el.focus && el.focus()
        el.scrollIntoView()
    }
}
