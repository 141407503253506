import React from 'react'
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { format } from 'date-fns'

const styles = StyleSheet.create({
    page: {
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: 30,
    },
    h1: {
        fontSize: 14,
        fontWeight: 'bold',
        marginVertical: 5,
    },
    p: {
        fontSize: 10,
        marginVertical: 8,
        textTransform: "capitalize"
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        width: '90%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    textField: {
        borderBottomColor: '#666',
        borderBottomWidth: 1,
        borderBottom: 'solid',
        marginTop: 10,
        maxWidth: 200,
    },
})


const pageStyle = {
    paddingTop: 16,
    paddingHorizontal: 40,
    paddingBottom: 56
  };
  
  const tableStyle = {
    display: "table",
    width: "auto"
  };
  
  const tableRowStyle = {
    flexDirection: "row"
  };
  
  const firstTableColHeaderStyle = {
    width: "33%",
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    backgroundColor: "#cccccc"
  };
  
  const tableColHeaderStyle = {
    width: "33%",
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: "#bdbdbd"
  };
  
  const firstTableColStyle = {
    width: "33%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderTopWidth: 0
  };
  
  const tableColStyle = {
    width: "33%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  };
  
  const tableCellHeaderStyle = {
    textAlign: "center",
    margin: 4,
    fontSize: 12,
    fontWeight: "bold"
  };
  
  const tableCellStyle = {
    textAlign: "center",
    margin: 5,
    fontSize: 10
  };
  

const ManifestPdf = (props) => {
    const { results, location } = props

    const createTableHeader = () => {
        return (
          <View style={tableRowStyle} fixed>
    
            <View style={firstTableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Name</Text>
            </View>
    
            <View style={tableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Location</Text>
            </View>
    
            <View style={tableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Testing Date</Text>
            </View>
    
          </View>
        );
      };


    const createTableRow = () => {
        return (
            results.map((row) => (
                <View style={tableRowStyle} key={row.id}>
            
                    <View style={firstTableColStyle}>
                        <Text style={tableCellStyle}>{`${row.firstName} ${row.lastName}`}</Text>
                    </View>
            
                    <View style={tableColStyle}>
                        <Text style={tableCellStyle}>{row.location}</Text>
                    </View>
            
                    <View style={tableColStyle}>
                        <Text style={tableCellStyle}>{row.testDate instanceof Date && !isNaN(row.testDate) ? format(row.testDate, 'MM/d/yyyy H:mm') : 'Invalid Date'}</Text>
                    </View>
        
                </View>
            ))
        )
      };

    return (
        <Document>
            <Page wrap size="A4" style={styles.page}>
                <View>
                    {/* <View style={styles.header}>
                        <Image style={{ marginBottom: 10, width: 200 }} src={"mainlogo.svg"} />
                    </View> */}
                    <View style={{ ...styles.row, marginBottom: 15, alignItems: 'start', justifyContent: 'start' }}>
                        <Text>{`Manifest for ${location} - ${format(new Date(), 'MM/d/yyyy')}`}</Text>
                    </View>
                    <View style={tableStyle}>
                        {createTableHeader()}
                        {createTableRow()}
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default ManifestPdf
