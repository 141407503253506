import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function CustomSnackbar() {
    const { msg = {} } = useSelector((state) => state.Application)
    const [lastMsg, setLastMsg] = useState({})
    const [open, setOpen] = useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    useEffect(() => {
        if (msg?.text && lastMsg !== msg) {
            setLastMsg(msg)
            setOpen(true)
        }
    }, [msg, lastMsg])

    return (
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert elevation={6} variant="filled" severity={lastMsg?.severity} onClose={handleClose}>
                {lastMsg?.text}
            </Alert>
        </Snackbar>
    )
}
