import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '2%', justifyContent: 'center' }}>
            <CircularProgress color="primary" />
        </div>
    )
}

export default Loading
