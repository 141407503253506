import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const colorPrimary = '#A6085F'
const colorSecondary = '#9AFFFD'

const theme = createTheme({
    overrides: {
        MuiAppBar: {
            colorPrimary: { backgroundColor: '#1E1B55' },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                minWidth: '200px',
                marginBottom: '10px',
            },
            containedPrimary: {
                'background-color': '#A6085F',
                '&:hover': {
                    backgroundColor: '#020659',
                    borderColor: '#020659',
                },
                '&:active': {
                    backgroundColor: '#A6085F',
                    borderColor: '#A6085F',
                },
            },
        },
        MuiCard: {
            root: {
                //maxWidth: 350,
                width: '100%',
            },
        },
        MuiTextField: {
            root: {
                marginBottom: 10,
                width: '100%',
            },
        },
        MuiToolbar: {
            root: {
                color: 'white',
            },
        },
    },
    palette: {
        primary: {
            main: colorPrimary,
        },
        secondary: {
            main: colorSecondary,
        },
    },
    typography: {
        fontFamily: 'Arial',
        fontSize: 14,
    },
})

theme.props = {
    MuiRadio: {
        color: 'primary',
    },
    MuiCheckbox: {
        color: 'primary',
    },
}

export default responsiveFontSizes(theme)
