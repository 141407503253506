export const styles = {
    patientSearch: {
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
        maxWidth: '1000px',
        height: '90%',
        margin: '5% 0',
        alignItems: 'center',
        justifyContent: 'center',
    },
    viewPatient: {
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        margin: '5% 0',
        alignItems: 'center',
        justifyContent: 'center',
    },
    patientConfirm: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '5%',
    },
    item: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',

        '& > p': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
        },

        '& div': {
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
    },
    barcode: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    demoFormContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '80vw',
        maxWidth: '1920px',
        height: '90%',
        margin: '5% 0',
        padding: '2% 5vw',
        border: '1px solid grey',

        '& > *': {
            marginBottom: '15px',
        },
    },
    releaseStyle: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        minHeight: '500px',

        '& > p': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
            marginBottom: '10px',
        },

        '& div': {
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '250px',
        height: '250px',
        margin: 'auto',
        backgroundColor: '#fff',
        borderRadius: '10%',
        marginTop: '15%',
    },
}
