import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import List from '@mui/material/List';

import AppBar from './AppBar'
import MenuDrawer from './MenuDrawer'
import { reqLogout } from '../../redux/modules/authentication'

const useStyles = makeStyles((theme) => ({
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'auto !important',
        padding: 20,
        '&:after': {
            height: 50,
        },
    },
    pageWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 150,
    },
    link: {
        textDecoration: 'none',
        fontSize: '14px',
        color: 'inherit',
        padding: '8%',
        textAlign: 'center',
    },
}))

export default function ProtectedPage(props) {
    const { children } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const [showMenu, setShowMenu] = useState(false)
    const { Authentication: { provider } } = useSelector((state) => state)

    const handleLogout = () => {
        dispatch(reqLogout())
    }

    return (
        <div className={classes.pageWrapper}>
            <AppBar onMenuClick={() => setShowMenu(true)} />
            {/* <MenuDrawer open={showMenu} onClose={() => setShowMenu(false)}>
                {provider ? (
                    <List className={classes.drawerContent}>
                        <Link to="/on-site" className={classes.link}>
                            Process Patients
                        </Link>
                        <Link to="/manifest" className={classes.link}>
                            Patient Manifest
                        </Link>
                        <Button onClick={handleLogout}>Logout</Button>
                    </List>
                ) : (
                    <List className={classes.drawerContent}>
                        <Button onClick={handleLogout}>Logout</Button>
                    </List>
                )}
            </MenuDrawer> */}
            <Container maxWidth="lg" className={classes.containerWrapper}>
                {children}
            </Container>
        </div>
    )
}
