export const styles = {
    wrapper: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '5%',
        marginBottom: '25px',
    },
    newUpload: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '2%',
        marginBottom: '2%',

        '& button': {
            backgroundColor: '#fff',
            margin: 'auto',
            marginTop: '2%',
        },
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
        maxWidth: '80vw',
        marginTop: '15px',
        marginBottom: '15px',
    },
    row: {
        display: 'flex',
        width: '100%',
        height: '50px',
        marginBottom: '2%',
        justifyContent: 'space-between',
        borderBottom: '1px solid grey',

        '& p, h6': {
            width: '15%',
            textAlign: 'center',
        },
    },
    dropZone: {
        height: '150px',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
}
