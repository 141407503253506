export const styles = {
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '75vw',
        maxWidth: '1920px',
        height: '90%',
        margin: '5% 0',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '5%',
    },
    formRow: {
        maxWidth: '700px',
    },
    item: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-between',

        '& > p': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
        },

        '& div': {
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
    },
    demoFormContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '80vw',
        maxWidth: '1920px',
        height: '90%',
        margin: '5% 0',
        padding: '2% 5vw',
        border: '1px solid grey',

        '& > *': {
            marginBottom: '15px',
        },
    },
    releaseStyle: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',

        '& > p': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
            marginBottom: '10px',
        },

        '& div': {
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
    },
    complete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '600px',
        width: '80%',
        maxWidth: '1300px',
        margin: 'auto',
        padding: '50px',

        '& p, h4, h5, h6': {
            textAlign: 'center',
        },
    },
    barcode: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px',

        '& > div': {
            width: '50%',
            margin: 'auto',
        },
    },
}

export const mobileStyles = {
    ...styles,
    section: {
        ...styles.section,
    },
    item: {
        ...styles.item,
        width: '100%',
        maxWidth: '400px',
        justifyContent: 'space-between',
        marginBottom: '2%',
        '& > p': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100px',
        },
    },
}
