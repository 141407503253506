import * as Yup from 'yup'
import { some, values } from 'lodash'
import moment from 'moment'

export const symptoms = [
    'Cough',
    'Chills',
    'Shortness of Breath',
    'Sore Throat',
    'Body Aches',
    'Diarrhea',
    'Vomiting',
    'Fever',
    'Headache',
    'Runny Nose',
    'Increased Fatigue',
    'Lack of Taste/Smell',
    'Abdominal Pain',
]

export const genders = [    
    "Male", 
    "Female", 
    "Transgender Male", 
    "Transgender Female", 
    "Non-binary", 
    "Genderqueer", 
    "Two spirit", 
    "Agender",
    "Others",
    "Prefer not to identify", 
]

export const races = ['American Indian or Alaska Native', 'Black or African American', 'White', 'Native Hawaiian or Other Pacific Islander', 'Asian']

const patientFormSchemaDefine = {
    essentialWorker: Yup.bool().default(null).typeError('Are an essential worker?').required('Are an essential worker?'),
    haveSymptoms: Yup.bool().default(false).typeError('Do you have any symptoms?').required('Do you have any symptoms?'),
    essentialWorkerType: Yup.string()
        .default(null)
        .nullable()
        .when('essentialWorker', {
            is: true,
            then: (schema) => schema.required('What type of essential worker?'),
            otherwise: (schema) => schema,
        }),
    symptoms: Yup.object().shape(
        symptoms.reduce((result, item) => {
            result[item] = Yup.bool().default(false)
            return result
        }, {})
    ),
    symptomStartDate: Yup.date()
        .default(null)
        .nullable()
        .test({
            name: 'haveSymptoms',
            message: 'Date Symptoms Began',
            test: (value, { parent: { symptoms } }) => {
                if (some(values(symptoms))) {
                    try {
                        return moment(value).isValid()
                    } catch {}
                    return false
                }
                return true
            },
        }),
    referred: Yup.bool()
        .default(null)
        .typeError('Have been referred by a State or Municipal Health Official?')
        .required('Have been referred by a State or Municipal Health Official?'),
    previouslyTested: Yup.bool().default(null).typeError('Have you had a COVID-19 test previously?').required('Have you had a COVID-19 test previously?'),
    release: Yup.bool().default(null).typeError('You must agree with these terms').oneOf([true], 'You must agree with these terms'),
    firstName: Yup.string().default(null).typeError('First Name').required('First Name'),
    lastName: Yup.string().default(null).typeError('Last Name').required('Last Name'),
    middleInitial: Yup.string().default(null).nullable(),
    preferredName: Yup.string().default(null).typeError("Name you'd like to be called").required("Name you'd like to be called"),
    address: Yup.string().default(null).typeError('Address').required('Address'),
    city: Yup.string().default(null).typeError('City').required('City'),
    state: Yup.string().default(null).typeError('State').required('State'),
    zip: Yup.string().default(null).typeError('Zip').required('Zip'),
    dateOfBirth: Yup.date().default(null).typeError('Date of Birth').required('Date of Birth'),
    sexAssignedAtBirth: Yup.string().default(null).typeError('Sex Assigned at Birth').required('Sex Assigned at Birth'),
    email: Yup.string().default(null).email('Email').required('Email'),
    phone: Yup.string().default(null).typeError('Phone').required('Phone'),
    phoneType: Yup.string().default(null).typeError('Phone Type').required('Phone Type'),
    mayWeText: Yup.string().default(null).typeError('May We Text?').required('May We Text?'),
    // pronouns: Yup.string().default(null).typeError('Pronouns').required('Pronouns'),
    genders: Yup.object().shape(
        genders.reduce((result, item) => {
            result[item] = Yup.bool().default(false)
            return result
        }, {})
    ),
    races: Yup.object().shape(
        races.reduce((result, item) => {
            result[item] = Yup.bool().default(false)
            return result
        }, {})
    ),
    ethnicity: Yup.string().default(null).typeError('Ethnicity').required('Ethnicity'),
    pregnant: Yup.mixed().default(null).typeError('Are you currently pregnant?').required('Are you currently pregnant?'),
}

const providerPatientFormSchemaDefine = Object.assign(
    {
        testingSite: Yup.string().default(null).typeError('Select testing location').required('Select testing location'),
        collectionDate: Yup.date().default(moment().toDate()).typeError('Select sample collection date').required('Select sample collection date'),
    },
    patientFormSchemaDefine
)

export const patientFormSchema = Yup.object().shape(patientFormSchemaDefine)
export const providerPatientFormSchema = Yup.object().shape(providerPatientFormSchemaDefine)
