import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import PdfLogo from './PdfLogo.js'

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 30,
        width: '100%',
    },
    logo: {
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
    },
    info: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        textAlign: 'start',
    },
    text: {
        fontSize: 8,
        marginHorizontal: 10,
    },
})

export default function PdfHeader() {
    return (
        <View style={styles.header} fixed>
            <View style={styles.logo}>
                <PdfLogo></PdfLogo>
            </View>
            <View style={styles.info}>
                <Text style={styles.text}>Nexsun Diagnostic Laboratories,LLC</Text>
                <Text style={styles.text}>15375 Barranca Parkway, Suite A-110, Irvine</Text>
                <Text style={styles.text}>CA 92618</Text>
                <Text style={styles.text}>949-783-7340</Text>
                <Text style={styles.text}>CLIA#: 05D2244809</Text>
                <Text style={styles.text}>www.nexsunlabs.com</Text>
            </View>
        </View>
    )
}
