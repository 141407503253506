import React from 'react'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, LocalizationProvider  } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format, startOfDay, addDays } from 'date-fns'

import { setMsg } from '../../../redux/modules/application/actions'
import { useDeviceStyles } from '../../../customHooks'
import ProtectedPage from '../../common/ProtectedPage'
import { findPatients } from '../../../services/firebase'

import { Button, Select, TextField, MenuItem, FormControl, InputLabel } from '@mui/material'
import ResultsGrid from './ResultsGrid'
import Loading from '../../common/Loading'

import { styles } from './styles'

const formatDate = (date) => {
    let [day, time] = date.split(' ')

    if (!day) return ''

    day = day.slice(0, 4) + '-' + day.slice(4, 6) + '-' + day.slice(6)
    day = format(addDays(startOfDay(new Date(day)), 1), 'yyyy-MM-dd')
    day = day + `T${time || '00:00'}:00`

    return new Date(day)
}

const Manifest = () => {
    const dispatch = useDispatch()
    const {
        Authentication: { provider },
    } = useSelector((state) => state)
    const {
        Application: { logoSrc },
    } = useSelector((state) => state)

    const classes = useDeviceStyles({ styles })

    const [redirect, setRedirect] = React.useState(null)
    const [locations, setLocations] = React.useState([])
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [selectedLocation, selectLocation] = React.useState('')
    const [startDate, setStart] = React.useState(new Date())
    const [endDate, setEnd] = React.useState(new Date())

    React.useEffect(() => {
        if (!provider) {
            setRedirect('/')
        } else if (provider.location && typeof provider.location === 'string') {
            setLocations(provider.location.split(','))
        }
    }, [provider])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    const handleDateChange = (date, side) => {
        if (side === 'start') {
            setStart(date)
        } else {
            setEnd(date)
        }
    }

    const handleSubmit = async () => {
        if (!selectedLocation) {
            dispatch(setMsg({ text: 'Must select a location', severity: 'error' }))
            return
        }

        setLoading(true)

        const res = await findPatients({
            location: selectedLocation,
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
        })

        setLoading(false)

        if (res && res.length) {
            let sorted = res.map((row) => {
                return { ...row, testDate: formatDate(row.testDate) }
            })

            sorted = sorted.sort((a, b) => {
                return a.testDate - b.testDate
            })

            setResults(sorted)
        } else {
            setResults([])
            dispatch(setMsg({ text: 'No patients found', severity: 'info' }))
        }
    }

    return (
        <ProtectedPage>
            
            <div className={classes.dates} style={{ maxWidth: "320px", display: "flex", gap: "15px", margin: "0 auto" }}>
                <h3>Patient Manifest</h3>
                <FormControl className={classes.input} style={{ width: "100%" }}>
                    <InputLabel>Location</InputLabel>
                    <Select fullWidth value={selectedLocation} onChange={(e) => selectLocation(e.target.value)}>
                        {locations.map((loc) => (
                            <MenuItem key={loc} value={loc}>
                                {loc}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        renderInput={(params) => <TextField fullWidth {...params} />}
                        style={{ width: '40%' }}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline-dob"
                        label="Start Date"
                        value={startDate}
                        onChange={(e) => handleDateChange(e, 'start')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </LocalizationProvider >

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        renderInput={(params) => <TextField fullWidth {...params} />}
                        style={{ width: '40%' }}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline-dob"
                        label="End Date"
                        value={endDate}
                        onChange={(e) => handleDateChange(e, 'end')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </LocalizationProvider>

                {loading ? <Loading /> : <Button fullWidth variant="contained" onClick={handleSubmit}>Submit</Button>}
            </div>

            <ResultsGrid results={results} location={selectedLocation} />
        </ProtectedPage>
    )
}

export default Manifest
