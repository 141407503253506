import React from 'react'
import { Controller } from 'react-hook-form'
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, Box, FormHelperText } from '@mui/material'
import { makeStyles } from "@mui/styles"

import { styles } from './styles'

const Release = (props) => {
    const useStyles = makeStyles(styles)
    const classes = useStyles()

    const {
        form: { control },
    } = props

    return (
        <div className={classes.releaseStyle}>
            <Typography variant="body1">
                I authorize Nexsun Labs to perform testing for COVID-19. Checking the box below acknowledges that I have been informed of the benefits and limitations of the test
                and that they have been explained by a qualified healthcare professional.{' '}
            </Typography>

            <Typography variant="body1">
                By checking the box below, I certify that I provided my unadulterated specimen to the collector to be analyzed; that the information I provided and, on the label
                affixed to the specimen is correct; and the specimen to be tested was sealed in my presence. I acknowledge that Nexsun Labs has my permission to release my results
                to the appropriate state and local health authorities. Your information and your test results are protected and kept confidential and will only be shared with the
                appropriate state and local health authorities as required by state law. If this sample is of a minor, this check box constitutes parental/guardian consent.
            </Typography>

            <Typography variant="body1">
                I understand that this test does NOT screen for the presence of antibodies IgG/IgM. Negative results may NOT rule out COVID-19 infection, and additional testing may
                be required. I understand that this test does not determine my level of immunity to COVID-19.
            </Typography>

            <Typography variant="body1">
                I understand that I am not entering into a doctor-patient relationship with Nexsun Labs or the ordering medical doctor, and that any questions or required follow up
                shall be my responsibility to arrange with my own physician.
            </Typography>

            <Typography variant="body1">
                I understand the information on this form, including that my test results will be provided to the appropriate state and local health authorities, and I voluntarily
                consent to have a COVID-19 nasal swab (or saliva) nucleic acid test.
            </Typography>
            <Controller
                name="release"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                    return (
                        <FormControl error={invalid} style={{ width: '100%' }}>
                            <Box id="release" display="flex" justifyContent="flex-start" alignItems="center">
                                <RadioGroup name="release" value={value} onChange={(evt) => onChange(evt.target.value === 'true')}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{invalid ? 'You must agree with these terms' : ''}</FormHelperText>
                            </Box>
                        </FormControl>
                    )
                }}
            />
        </div>
    )
}

export default Release
