export const styles = {
    input: {
        width: 300,
        marginTop: 15,
    },
    dates: {
        width: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    resultsGrid: {
        display: 'flex',
        flexDirection: 'column',
        width: 800,

        '& button': {
            width: 100,
        },
    },
}
