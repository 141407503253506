import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles'

export const useDeviceStyles = (props) => {
    let { tabletStyles, mobileStyles, styles } = props
    if (!mobileStyles) mobileStyles = { ...styles }
    if (!tabletStyles) tabletStyles = { ...mobileStyles }

    const isTablet = useMediaQuery('@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)', { noSsr: true })
    const isMobile = useMediaQuery('@media only screen and (min-device-width: 250px) and (max-device-width: 812px)', { noSsr: true })

    let selectedStyle

    if (isTablet) {
        selectedStyle = tabletStyles
    } else if (isMobile) {
        selectedStyle = mobileStyles
    } else {
        selectedStyle = styles
    }

    const useStyles = makeStyles(selectedStyle)
    return useStyles(selectedStyle)
}
