import actions from './actions'

const initialState = {
    tests: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_TESTS:
            return {
                ...state,
                tests: action.tests,
            }

        default:
            return state
    }
}
