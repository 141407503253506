import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Button, Card, CardContent, TextField, Typography } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { PDFDownloadLink } from '@react-pdf/renderer'
import BreadCrumbs from '../../common/BreadCrumbs'
import PDFView from './pdfView'
import LabHeader from './labHeader'
import AdditionalInfo from './additionalInfo'
import PatientInfo from './patientInfo'
import ProviderInfo from './providerInfo'
import Result from './result'

const useStyles = makeStyles((theme) => ({
    testPage: {
        height: '100%',
        '& a': {
            textDecoration: 'none',
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    heading: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    cardWrapper: {
        marginTop: 10,
    },
    infoWrapper: {
        maxWidth: 500,
        minWidth: 275,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 5px',
    },
    cta: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: -24,
        width: '100%',
    },
}))

export default function TestView() {
    const classes = useStyles()
    const { index } = useParams()
    const { Application, Tests } = useSelector((state) => state)
    const { logoSrc } = Application
    const { tests = [] } = Tests
    const resultsRef = useRef()
    const test = tests.length ? tests[index] : {}
    const {
        labAddress,
        labName,
        labPhone,
        labCLIA,
        labDirector,
        approvingPerson,
        approvingPersonTitle,
        patientAccessionID,
        patientAddress1,
        patientAddress2,
        patientAddressCity,
        patientAddressCounty,
        patientAddressState,
        patientAddressZip,
        patientDOB,
        patientEmail,
        patientEthnicity,
        patientFirstName,
        patientGender,
        patientLastName,
        patientPhone,
        patientRace,
        providerAddress1,
        providerAddress2,
        providerCity,
        providerNPI,
        providerName,
        providerPhone,
        providerState,
        providerZip,
        resultDefinition,
        testName,
        testResultDate,
        resultText,
        testSpecimenType,
        sampleReceivedDate,
        labSpecificVerbiage1,
        labSpecificVerbiage2,
        labSpecificVerbiage3,
        serverTimestamp,
        ...restData
    } = test

    let signature
    const signatures = {
        mehrdad: 'https://firebasestorage.googleapis.com/v0/b/nexsun-3246d.appspot.com/o/labs%2Fatcg%2Fmehrdad-signature.png?alt=media',
    }

    switch (approvingPerson) {
        case 'Mehrdad Zoleikhaeian, CGMBS, MB(ASCP)':
            signature = signatures.mehrdad
            break
        default:
            signature = ''
            break
    }

    const breadcrumbs = [
        {
            path: '/',
            label: 'Back',
        },
    ]

    const otherData = () =>
        restData.length ? (
            <div className={classes.infoWrapper}>
                <Typography variant="h4" className={classes.heading}>
                    Other
                </Typography>
                {Object.keys(restData)
                    .sort()
                    .map((key, i) => test[key] && <TextField key={i} multiline defaultValue={test[key]} label={key} inputProps={{ readOnly: true }} />)}
            </div>
        ) : null

    const resultsPage = (
        <>
            <div style={{ textAlign:"center", paddingTop: "25px" }}><img src={logoSrc} className="main-logo" alt="Nexsun Diagnostic Laboratories" style={{ height: "50px", margin: "0 auto" }} /></div>
            <LabHeader data={{ labAddress, labName, labPhone, labCLIA, labDirector }} logo={logoSrc} />
            <div className={classes.row}>
                <div className={classes.infoWrapper}>
                    <Result
                        signature={signature}
                        data={{
                            resultDefinition,
                            resultText,
                            approvingPerson,
                            approvingPersonTitle,
                            patientAccessionID,
                            testName,
                            testResultDate,
                            testSpecimenType,
                            sampleReceivedDate,
                        }}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.infoWrapper}>
                    <PatientInfo
                        data={{
                            patientAddress1,
                            patientAddress2,
                            patientAddressCity,
                            patientAddressCounty,
                            patientAddressState,
                            patientAddressZip,
                            patientDOB,
                            patientEmail,
                            patientEthnicity,
                            patientFirstName,
                            patientGender,
                            patientLastName,
                            patientPhone,
                            patientRace,
                            patientAccessionID,
                            testName,
                            testResultDate,
                            testSpecimenType,
                            sampleReceivedDate,
                        }}
                    />
                </div>
                <div className={classes.infoWrapper}>
                    <ProviderInfo data={{ providerAddress1, providerAddress2, providerCity, providerNPI, providerName, providerPhone, providerState, providerZip }} />
                </div>
                {otherData(restData)}
            </div>
            <div className={classes.row}>
                <AdditionalInfo data={{ labSpecificVerbiage1, labSpecificVerbiage2, labSpecificVerbiage3 }} />
            </div>
        </>
    )

    const pdfLink = Object.keys(test).length && (
        <PDFDownloadLink document={<PDFView data={test} logo={logoSrc} signature={signature} />} fileName="test-results.pdf">
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" color="primary">
                        Loading...
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" startIcon={<SaveAltIcon />}>
                        Download PDF
                    </Button>
                )
            }
        </PDFDownloadLink>
    )

return (
        <div className={classes.testPage}>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
            <div className={classes.cta}>{pdfLink}</div>
            <div className={classes.cardWrapper} ref={resultsRef}>
                <Card>
                    <CardContent>{resultsPage}</CardContent>
                </Card>
            </div>
        </div>
    )
}
