import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useDeviceStyles } from '../../../customHooks'
import { uploadAuthProviders } from '../../../services/firebase'

import { Typography, Button, CircularProgress, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import PublishIcon from '@mui/icons-material/Publish'

import { styles } from './styles'

const ProviderUpload = () => {
    const classes = useDeviceStyles({ styles })

    const [newProviderData, setProviderData] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const readFile = async (files) => {
        if (!('TextDecoder' in window)) alert('This browser does not support decoding txt files. Please try the most recent version of Chrome, Firefox or Safari.')

        const file = files[0]
        if (!file.path.includes('csv')) {
            window.alert('File must use a .csv extension')
            return
        }
        const reader = new FileReader()

        reader.onabort = () => console.error('file reading was aborted')
        reader.onerror = () => console.error('file reading has failed')

        reader.onload = () => {
            const binaryStr = reader.result

            const enc = new TextDecoder('utf-8')
            const raw = enc.decode(binaryStr)

            if (raw) {
                let arr = raw.trim().split('\n')

                arr = arr.map((row) => {
                    const parsed = row.split(',')

                    const email = parsed.shift().toLowerCase()

                    let locations = parsed.map((loc) => {
                        return loc.trim().toLowerCase().replace(/"/gi, '')
                    })

                    return {
                        email: email ? email.trim() : '',
                        location: locations.join(','),
                    }
                })

                setProviderData(arr)
            }
        }

        reader.readAsArrayBuffer(file)
    }

    const onDrop = React.useCallback((files) => {
        readFile(files)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const handleUpload = async () => {
        try {
            if (!newProviderData || !newProviderData.length) return

            setLoading(true)
            await uploadAuthProviders(newProviderData)
            setLoading(false)

            window.alert('Success')
        } catch (e) {
            window.alert(`Error: ${e.message}`)
        }
    }

    return (
        <div className={classes.wrapper}>
            <Typography variant="h5">Upload Authorized Providers</Typography>
            <div className={classes.newUpload} style={{ marginTop: '0' }}>
                <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {newProviderData.length ? (
                        <Typography variant="h6">{`${newProviderData.length} emails ready to upload`}</Typography>
                    ) : isDragActive ? (
                        <Typography variant="h6">Drop file here</Typography>
                    ) : (
                        <div>
                            <Typography variant="h6">Drag file here or click to select</Typography>
                            <PublishIcon fontSize="large" />
                        </div>
                    )}
                </div>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <Button disabled={!newProviderData.length} onClick={handleUpload}>
                        {newProviderData.length ? 'Upload' : 'Select a File'}
                    </Button>
                )}
                <Typography style={{ marginTop: '5%', textAlign: 'center' }} variant="body2">
                    File should contain one column for email address and one for a comma separated location list.
                </Typography>
                <Typography style={{ marginTop: '5%', textAlign: 'center' }} variant="body2">
                    test@test.com | Ruby Hill, Main Street, Downtown
                </Typography>
            </div>

            <TableContainer component={Paper} className={classes.table}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newProviderData && newProviderData.length
                            ? newProviderData.slice(0, 100).map((row, i) => (
                                  <TableRow key={i}>
                                      <TableCell align="center" component="th" scope="row">
                                          <Typography variant="body1">{row.email}</Typography>
                                      </TableCell>

                                      <TableCell align="center">
                                          <Typography variant="body1">{row.location}</Typography>
                                      </TableCell>
                                  </TableRow>
                              ))
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>

            {newProviderData && newProviderData.length > 100 ? <Typography variant="body1">{newProviderData.length - 100} More</Typography> : null}
        </div>
    )
}

export default ProviderUpload
