import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { store } from '../../../redux'

import { useDeviceStyles } from '../../../customHooks'
import { setShowLoader, setMsg } from '../../../redux/modules/application/actions'
import { findPatient, addNewPatient, updatePatient } from '../../../services/firebase'

import ProtectedPage from '../../common/ProtectedPage'
import PatientLookup from './PatientLookup'
import ViewPatient from './ViewPatient'
import PatientPDF from '../../common/PatientPDF'

import { Modal, Button, Typography } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import { styles } from './styles'

const OnSite = () => {
    const dispatch = useDispatch()
    const {
        Authentication: { provider },
    } = useSelector((state) => state)
    const {
        Application: { logoSrc },
    } = useSelector((state) => state)

    const classes = useDeviceStyles({ styles })

    const [complete, setComplete] = React.useState(false)
    const [redirect, setRedirect] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [patients, setPatients] = React.useState([])
    const [newPatient, setNewPatient] = React.useState(false)
    const [patient, setPatient] = React.useState(null)

    React.useEffect(() => {
        dispatch(setShowLoader(false))
    }, [])

    React.useEffect(() => {
        if (!provider) {
            setRedirect('/login')
        }
    }, [provider])

    const handleSearch = async (form) => {
        setLoading(true)
        const patients = await findPatient(form)
        setPatients(patients)
        setLoading(false)
    }

    const handlePatientUpdate = async (data) => {
        let dob = new Date(new Date(data.dateOfBirth).getTime() + new Date(data.dateOfBirth).getTimezoneOffset()*60*1000)
        const confirmation = window.confirm(`Please confirm date of birth: ${format(dob, 'yyyy-MM-dd')}`)
        if (!confirmation) return

        data.captureDate = format(new Date(), 'yyyyMMdd HH:mm')

        store.dispatch(setShowLoader(true))
        const res = await updatePatient(data)
        if (res) {
            dispatch(setMsg({ text: 'Patient updated successfully', severity: 'info' }))
            setPatient(data)
            setPatients([])
        } else {
            dispatch(setMsg({ text: 'Error updating patient.', severity: 'error' }))
        }
        store.dispatch(setShowLoader(false))

        setComplete(true)
    }

    const handleCreatePatient = async (data) => {
        const confirmation = window.confirm(`Please confirm date of birth: ${format(new Date(data.dateOfBirth), 'yyyy-MM-dd')}`)
        if (!confirmation) return

        setLoading(true)

        data.captureDate = format(new Date(), 'yyyyMMdd HH:mm')

        const res = await addNewPatient(data)

        if (res) {
            dispatch(setMsg({ text: 'Patient created successfully', severity: 'info' }))
        } else {
            dispatch(setMsg({ text: 'Error creating patient.', severity: 'error' }))
        }

        setNewPatient(false)
        setPatient(data)
        setLoading(false)
        setComplete(true)
    }

    const handleNewPatient = () => {
        // setPatient(null)
        // setNewPatient(true)
        setRedirect('/new-patient')
    }

    const handleClose = () => {
        setNewPatient(null)
        setPatient(null)
        setComplete(false)
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (!patient && !newPatient) {
        return (
            <ProtectedPage>
                {/* <img src={logoSrc} className="main-logo" alt="Nexsun Diagnostic Laboratories" /> */}
                <PatientLookup handleSearch={handleSearch} setPatient={setPatient} patients={patients} setNewPatient={handleNewPatient} loading={loading} />
            </ProtectedPage>
        )
    }

    return (
        <ProtectedPage>
            <ViewPatient back={() => setPatient(null)} newPatient={Boolean(newPatient)} patient={patient} handleSubmit={newPatient ? handleCreatePatient : handlePatientUpdate} loading={loading} />

            <Modal open={complete} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className={classes.modal}>
                    <Typography>Patient updated successfully!</Typography>
                    <PDFDownloadLink document={<PatientPDF data={patient} logo={logoSrc} />} fileName="patient-data.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? (
                                <Button variant="contained" color="primary">
                                    Loading...
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary" startIcon={<SaveAltIcon />}>
                                    Download PDF
                                </Button>
                            )
                        }
                    </PDFDownloadLink>
                </div>
            </Modal>
        </ProtectedPage>
    )
}

export default OnSite
