import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter } from 'connected-react-router'
import { fork } from 'redux-saga/effects'
import { Authentication, authenticationSagas } from './authentication'
import { Application } from './application'
import { Tests, testsSagas } from './tests'

const persistConfig = {
    key: 'root',
    storage,
}

export const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        persisted: persistReducer(persistConfig, Application /* Add persisted reducers here */),
        Application,
        Authentication,
        Tests /* Add all reducers here */,
    })

export function* rootSaga() {
    yield fork(authenticationSagas)
    yield fork(testsSagas)
}
