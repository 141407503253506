import actions from './actions'

const initialState = {
    authenticated: false,
    user: null,
    provider: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: action.authenticated,
            }

        case actions.SET_USER:
            return {
                ...state,
                user: action.user,
            }

        case actions.SET_PROVIDER:
            return {
                ...state,
                provider: action.provider,
            }

        default:
            return state
    }
}
