import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    labHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        '& img': {
            display: 'flex',
            width: '100%',
            maxWidth: 300,
            marginBottom: 20,
        },
        '& p': {
            display: 'flex',
        },
    },
}))

export default function LabHeader({ data, logo }) {
    const classes = useStyles()
    const { labAddress, labPhone, labCLIA, labDirector } = data

    return (
        <div className={classes.labHeader}>
            {/* <img src={logo} style={{ maxWidth: '400px' }} alt="Nexsun Diagnostic Laboratories" /> */}
            <Typography align="center">{labAddress}</Typography>
            <Typography align="center">{labCLIA}</Typography>
            <Typography align="center">{labPhone}</Typography>
            <Typography align="center">{labDirector}</Typography>
        </div>
    )
}
