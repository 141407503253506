import React from 'react'
import { useDeviceStyles } from '../../../customHooks'

import { Typography } from '@mui/material'

import { styles } from './styles'

const Complete = (props) => {
    const { logo } = props
    const classes = useDeviceStyles({ styles })

    return (
        <div className={classes.complete}>
            {/* <img src={logo} alt="Nexsun Diagnostic Laboratories" style={{ width: '400px', marginBottom: '5%' }} /> */}

            <Typography variant="h4">Your COVID19 patient registration has been completed successfully!</Typography>

            <Typography variant="body1" style={{ fontSize: '18px' }}>
                If you haven’t done so already, please contact your local testing facility to schedule your COVID19 test. The completion of this registration does not guarantee a
                COVID19 test. If you have any questions/concerns, please contact your local public health agency.
            </Typography>

            <Typography variant="body1" style={{ fontSize: '18px' }}>
                We thank you for participating in the betterment of public health. Please stay safe and have a great day.
            </Typography>
        </div>
    )
}

export default Complete
