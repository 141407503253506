import React from 'react'
import { Link } from 'react-router-dom'

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        display: 'flex',
        flexDirection: 'row',
        '& a': {
            textDecoration: 'none',
        },
    },
}))

export default function BreadCrumbs(props) {
    const { breadcrumbs = [] } = props
    const classes = useStyles()

    const renderBreadcrumbs = () =>
        breadcrumbs.map((link, i) =>
            link.label.length && link.path ? (
                <Typography key={i}>
                    <Link to={link.path}>{link.label}</Link>
                    {i < breadcrumbs.length - 1 ? <span style={{ margin: '0px 10px' }}>{`>`}</span> : null}
                </Typography>
            ) : link.label.length ? (
                <Typography key={i}>{link.label}</Typography>
            ) : null
        )

    return <div className={classes.breadcrumbs}>{breadcrumbs.length ? renderBreadcrumbs() : null}</div>
}
