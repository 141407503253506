import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import { isValidEmail } from '../../../utilities/helpers'

const useStyles = makeStyles(() => ({
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            marginBottom: 20,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    patientIdField: {
        '& label': {
            color: 'blue',
        },
    },
}))

export default function NewAcct(props) {
    const { onChangeView, onSubmit } = props
    const classes = useStyles()
    const [errors, setErrors] = useState({ email: false, password: false, password2: false })
    const [values, setValues] = useState({ email: '', password: '', password2: '' })

    const handleChange = (event) => setValues({ ...values, [event.target.name]: event.target.value })
    const handleChangeEmail = (event) => setValues({ ...values, email: event.target.value.toLowerCase().trim() })
    const handleKeyPress = (event) => (event.key === 'Enter' ? validate() : null)

    const validate = () => {
        setErrors({ email: false, password: false, password2: false })

        if (isValidEmail(values.email) !== true) {
            setErrors({ ...errors, email: 'Must be a valid email' })
            return
        }

        if (values.password !== values.password2) {
            setErrors({ ...errors, password2: 'Passwords do not match' })
            return
        }

        if (values.password.length < 6 || values.password2.length < 6) {
            setErrors({ ...errors, password: 'Password must be at least 6 characters' })
            return
        }

        onSubmit(values)
    }

    return (
        <Card>
            <CardContent className={classes.content}>
                <Typography variant="h5">Register Provider Account</Typography>
                <TextField
                    name="email"
                    type="text"
                    label="Email"
                    helperText={errors.email ? errors.email : ''}
                    error={errors.email ? true : false}
                    value={values.email}
                    onChange={handleChangeEmail}
                    onKeyDown={handleKeyPress}
                />
                <TextField
                    name="password"
                    type="password"
                    label="Password"
                    helperText={errors.password}
                    error={errors.password ? true : false}
                    value={values.password}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
                <TextField
                    name="password2"
                    type="password"
                    label="Verify Password"
                    helperText={errors.password2}
                    error={errors.password2 ? true : false}
                    value={values.password2}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
            </CardContent>
            <CardActions className={classes.actions}>
                <Button variant="contained" color="primary" onClick={() => validate()}>
                    Submit
                </Button>
                <Button onClick={() => onChangeView('login')}>Back to Login</Button>
            </CardActions>
        </Card>
    )
}
