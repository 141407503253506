export const actions = {
    REQ_CREATE_ACCT: 'REQ_CREATE_ACCT',
    REQ_LOGIN: 'REQ_LOGIN',
    REQ_LOGOUT: 'REQ_LOGOUT',
    REQ_RESET_PASSWORD: 'REQ_RESET_PASSWORD',
    REQ_VALIDATE_ACCT: 'REQ_VALIDATE_ACCT',
    REQ_VALIDATE_ACCT_PATIENT_ID: 'REQ_VALIDATE_ACCT_PATIENT_ID',
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    SET_USER: 'SET_USER',
    SET_PROVIDER: 'SET_PROVIDER',
}

export const reqLogin = ({ email, password }) => ({ type: actions.REQ_LOGIN, email, password })
export const reqLogout = () => ({ type: actions.REQ_LOGOUT })
export const reqCreateAcct = ({ email, password }) => ({ type: actions.REQ_CREATE_ACCT, email, password })
export const reqResetPassword = (email) => ({ type: actions.REQ_RESET_PASSWORD, email })
export const reqValidateAcct = ({ email, dob, password }) => ({ type: actions.REQ_VALIDATE_ACCT, email, dob, password })
export const reqValidateAcctWithPatientId = ({ email, id, password }) => ({ type: actions.REQ_VALIDATE_ACCT_PATIENT_ID, email, id, password })
export const setAuthenticated = (authenticated) => ({ type: actions.SET_AUTHENTICATED, authenticated })
export const setUser = (user) => ({ type: actions.SET_USER, user })
export const setProvider = (provider) => ({ type: actions.SET_PROVIDER, provider })

export default actions
