import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatAddress } from '../../utilities/helpers'
import { format } from 'date-fns'
import moment from 'moment'
import PdfHeader from './PdfHeader'

const styles = StyleSheet.create({
    page: {
        color: 'black',
        paddingTop: '1in',
        paddingBottom: '0.75in',
        paddingHorizontal: '0.75in',
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
    },
    title: {
        fontSize: 26,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 30,
    },
    h3: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    p: {
        fontSize: 12,
        marginVertical: 10,
        textAlign: 'justify',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
    },
    colBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: '50%',
        height: 150,
        border: '1 solid black',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    textField: {
        borderBottomColor: '#666',
        borderBottomWidth: 1,
        borderBottom: 'solid',
        marginTop: 10,
    },
})

export default function PatientPDF(props) {
    const { data } = props
    console.log(data);
    const patientAddress = formatAddress({
        address1: data.address?.toString(),
        city    : data.city?.toString(),
        state   : data.state?.toString().toUpperCase(),
        zip     : data.zip?.toString(),
    })

    const renderText = (label, value) =>
        value ? (
            <View style={styles.textField}>
                <Text style={{ color: '#666', fontSize: 10 }}>{label}</Text>
                <Text style={{ fontSize: 14 }}>{value}</Text>
            </View>
        ) : null

    return (
        <Document>
            <Page wrap size="A4" style={styles.page}>
                {PdfHeader()}
                <View style={styles.mainContent}>
                    <Text style={styles.title}>Patient Requisition Form</Text>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={styles.h3}>Patient Info</Text>
                            {renderText('Name', `${data.firstName} ${data.lastName}`)}
                            {renderText('DOB', data.dateOfBirth)}
                            {renderText('Address', patientAddress)}
                            {renderText('Phone', data.phone)}
                            {renderText('Email', data.email)}
                        </View>
                        <View style={styles.colBox}>
                            {renderText('Testing Location', `${data.testingSite}`)}
                            {renderText('Testing Date', `${format(new Date(), 'yyyy-MM-dd')}`)}
                            {renderText('Collection Date', `${moment(data.collectionDate).format('YYYY-MM-DD')}`)}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
