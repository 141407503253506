import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    textField: {
        //marginTop: 3,
    },
    verbage: {
        margin: '10px 0',
        textAlign: 'justify',
    },
}))

export default function AdditionalInfo(props) {
    const { data } = props
    const classes = useStyles()
    const { labSpecificVerbiage1, labSpecificVerbiage2, labSpecificVerbiage3 } = data

    return (
        <div>
            <Typography variant="h4" className={classes.heading}>
                Additional Info
            </Typography>
            {labSpecificVerbiage1 ? (
                <div className={classes.verbage}>
                    <Typography variant="body1">{labSpecificVerbiage1}</Typography>
                </div>
            ) : null}
            {labSpecificVerbiage2 ? (
                <div className={classes.verbage}>
                    <Typography variant="body1">{labSpecificVerbiage2}</Typography>
                </div>
            ) : null}
            {labSpecificVerbiage3 ? (
                <div className={classes.verbage}>
                    <Typography variant="body1">{labSpecificVerbiage3}</Typography>
                </div>
            ) : null}
        </div>
    )
}
