import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import { isValidEmail } from '../../../utilities/helpers'
import Loading from '../../common/Loading'

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            marginBottom: 20,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default function Login(props) {
    const { onChangeView, onSubmit, loading } = props
    const classes = useStyles()
    const [errors, setErrors] = useState({ email: false, password: false })
    const [values, setValues] = useState({ email: '', password: '' })

    const handleChange = (event) => setValues({ ...values, [event.target.name]: event.target.value })
    const handleChangeEmail = (event) => setValues({ ...values, email: event.target.value.toLowerCase().trim() })
    const handleKeyPress = (event) => (event.key === 'Enter' ? validate() : null)
    const validate = () => {
        setErrors({ email: false, password: false })
        if (isValidEmail(values.email) !== true) {
            setErrors({ ...errors, email: 'Must be a valid email' })
            return
        }
        if (!values.password.length) {
            setErrors({ ...errors, password: 'You must enter a password' })
            return
        }
        onSubmit(values)
    }

    return (
        <Card>
            <CardContent className={classes.content}>
                <Typography variant="h5">Provider Login</Typography>
                <TextField
                    name="email"
                    type="text"
                    label="Email"
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={values.email}
                    onChange={handleChangeEmail}
                    onKeyDown={handleKeyPress}
                />
                <TextField
                    name="password"
                    type="password"
                    label="Password"
                    helperText={errors.password}
                    error={errors.password ? true : false}
                    value={values.password}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
            </CardContent>

            
            <CardActions className={classes.actions}>
                {loading ? (
                    <Loading />
                ) : (
                    <Button style={{ width: '200px' }} variant="contained" color="primary" onClick={() => validate()}>
                        Submit
                    </Button>
                )}
                <Button variant="contained" style={{ width: '200px' }} onClick={() => onChangeView('newAcct')}>
                    Register Account
                </Button>
                <Button variant="contained" style={{ width: '200px' }} onClick={() => onChangeView('forgotPassword')}>
                    Forgot Password?
                </Button>
                <Link to="/terms" style={{ fontSize: '12px' }}>
                    Terms and Conditions
                </Link>
            </CardActions>
        </Card>
    )
}
