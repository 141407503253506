import React from 'react'
import { useSelector } from 'react-redux'
import { useDeviceStyles } from '../../../customHooks'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { format } from 'date-fns'

import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import ManifestPdf from './ManifestPdf'
import { styles } from './styles'

const ResultsGrid = (props) => {
    const { results, location } = props
    const classes = useDeviceStyles({ styles })
    const { Application } = useSelector((state) => state)
    const { logoSrc } = Application

    if (!results || !results.length) {
        return <></>
    }

    const pdfLink = (
        <PDFDownloadLink document={<ManifestPdf results={results} logo={logoSrc} location={location} />} fileName={`${location}_manifest.pdf`}>
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" >
                        Loading...
                    </Button>
                ) : (
                    <Button variant="contained" startIcon={<SaveAltIcon />}>
                        Download PDF
                    </Button>
                )
            }
        </PDFDownloadLink>
    )

    return (
        <div>
            {pdfLink}
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Patient Name</TableCell>
                            <TableCell>Testing Location</TableCell>
                            <TableCell>Test Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>

                                <TableCell>{row.location}</TableCell>

                                <TableCell>{row.testDate instanceof Date && !isNaN(row.testDate) ? format(row.testDate, 'MM/d/yyyy H:mm') : 'Invalid Date'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ResultsGrid
