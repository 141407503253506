import { call, fork, put, takeLatest } from 'redux-saga/effects'
import actions, { reqCreateAcct } from './actions'
import { reqGetTests } from '../tests'
import { setMsg, setShowLoader } from '../application'
import { createEmailAccount, resetPassword, signInWithEmail, signOut, validateDOB, validatePatientID } from '../../../services/firebase'

function* reqCreateAcctSaga(action) {
    const { email, password } = action
    yield put(setShowLoader(true))
    yield call(createEmailAccount, email, password)
}

function* reqLoginSaga(action) {
    const { email, password } = action
    yield put(setShowLoader(true))
    yield call(signInWithEmail, email, password)
}

function* reqLogoutSaga() {
    yield put(setShowLoader(true))
    yield call(signOut)
}

function* reqResetPasswordSaga(action) {
    yield put(setShowLoader(true))
    yield call(resetPassword, action.email)
}

function* reqValidateAcctSaga(action) {
    console.log(action)
    const { email, dob, password } = action
    // yield put(setShowLoader(true))
    const res = yield call(validateDOB, email, dob.toLocaleDateString())

    if (res.result) {
        yield put(reqCreateAcct({ email, password }))
    } else {
        // yield put(setShowLoader(false))
        console.log("Hello world")
        const msg = res.msg
        yield put(setMsg({ text: msg, severity: 'error' }))
    }
}

function* reqValidateAcctWithPatientIdSaga(action) {
    const { email, id, password } = action
    yield put(setShowLoader(true))
    const res = yield call(validatePatientID, email, id)

    if (res.result) {
        yield put(reqCreateAcct({ email, password }))
    } else {
        yield put(setShowLoader(false))
        yield put(setMsg({ text: res.msg, severity: 'error' }))
    }
}

function* setUserSaga(action) {
    const { user } = action
    yield user && put(reqGetTests())
}
function* watchReqCreateAcctSaga() {
    yield takeLatest(actions.REQ_CREATE_ACCT, reqCreateAcctSaga)
}
function* watchReqLoginSaga() {
    yield takeLatest(actions.REQ_LOGIN, reqLoginSaga)
}
function* watchReqLogoutSaga() {
    yield takeLatest(actions.REQ_LOGOUT, reqLogoutSaga)
}
function* watchReqResetPasswordSaga() {
    yield takeLatest(actions.REQ_RESET_PASSWORD, reqResetPasswordSaga)
}
function* watchReqValidateAcctSaga() {
    yield takeLatest(actions.REQ_VALIDATE_ACCT, reqValidateAcctSaga)
}
function* watchReqValidateAcctWithPatientIdSaga() {
    yield takeLatest(actions.REQ_VALIDATE_ACCT_PATIENT_ID, reqValidateAcctWithPatientIdSaga)
}
function* watchSetUserSaga() {
    yield takeLatest(actions.SET_USER, setUserSaga)
}

export default function* authenticationSagas() {
    yield fork(watchReqCreateAcctSaga)
    yield fork(watchReqLoginSaga)
    yield fork(watchReqLogoutSaga)
    yield fork(watchReqResetPasswordSaga)
    yield fork(watchReqValidateAcctSaga)
    yield fork(watchReqValidateAcctWithPatientIdSaga)
    yield fork(watchSetUserSaga)
}
