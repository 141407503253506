export const actions = {
    SET_MSG: 'SET_MSG',
    SET_SHOW_LOADER: 'SET_SHOW_LOADER',
    SET_INITIAL_LOADING: 'SET_INITIAL_LOADING',
    SET_VIEW_DIMS: 'SET_VIEW_DIMS',
}

export const setViewDims = ({ height, width }) => ({ type: actions.SET_VIEW_DIMS, height, width })
export const setMsg = ({ text, severity }) => ({ type: actions.SET_MSG, text, severity })
export const setShowLoader = (showLoader) => ({ type: actions.SET_SHOW_LOADER, showLoader })
export const setInitialLoading = (initialLoading) => ({ type: actions.SET_INITIAL_LOADING, initialLoading })

export default actions
