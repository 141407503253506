import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from '../../utilities/helpers'

const useStyles = makeStyles((theme) => ({
    dataGrid: {
        backgroundColor: 'white',
        maxWidth: '90vw',
        height: '100%',
        width: '100%',
    },
}))

export default function TestsView(props) {
    const { tests = [] } = props
    const classes = useStyles()
    const loading = tests.length ? false : true
    const fieldNameMappings = {
        date: 'sampleReceivedDate',
        fName: 'patientFirstName',
        lName: 'patientLastName',
        result: 'resultText',
    }

    const columns = [
        {
            field: fieldNameMappings.date,
            headerName: 'Date',
            type: 'string',
            width: 100,
            disableClickEventBubbling: true,
            renderHeader: () => <strong>Test Date</strong>,
            renderCell: (params) => {
                const { value } = params
                return formatDate(value)
            },
        },
        {
            field: fieldNameMappings.lName,
            headerName: 'Patient',
            type: 'string',
            width: 125,
            disableClickEventBubbling: true,
            renderHeader: () => <strong>Patient Name</strong>,
            renderCell: (params) => {
                const { rowIndex } = params
                const test = tests[rowIndex]
                return test ? `${test[fieldNameMappings.fName]} ${test[fieldNameMappings.lName]}` : null
            },
        },
        {
            field: fieldNameMappings.result,
            headerName: 'Result',
            type: 'string',
            width: 100,
            disableClickEventBubbling: true,
            renderHeader: () => <strong>Result</strong>,
            renderCell: (params) => {
                console.log(params)
                const { id, value } = params
                return <Link to={`tests/${id}`}>{value ?? 'N/A'}</Link>
            },
        },
    ]

    const rows = tests.map((test, id) => ({ ...test, id }))

    return tests.length ? (
        <div style={{ width: '100%' }}>
            <DataGrid autoHeight className={classes.dataGrid} hideFooter disableSelectionOnClick loading={loading} rows={rows} columns={columns} />
        </div>
    ) : null
}
