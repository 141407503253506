import { call, fork, put, takeLatest, select } from 'redux-saga/effects'
import actions, { setTests } from './actions'
import { getUserTests } from '../../../services/firebase'

function* reqGetTestsSaga() {
    const state = yield select()
    const { authenticated, user } = state.Authentication
    const tests = yield authenticated && call(getUserTests, user.email)
    yield put(setTests(tests))
}
function* watchReqGetTestsSaga() {
    yield takeLatest(actions.REQ_GET_TESTS, reqGetTestsSaga)
}

export default function* authenticationSagas() {
    yield fork(watchReqGetTestsSaga)
}
